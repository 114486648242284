import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ImageContainer from './components/ImageContainer';
import About from './components/About';

function App() {
  const [about, setAbout] = useState(false)
  return (
    <div className="App">
      <div>
        <header className="App-header">
          <div>Brooklynn Art</div>
        </header>
        <div className='App-container'>
          {about ? <About /> : <ImageContainer />}
        </div>

        <footer className='App-footer'>
          {about ? <a onClick={() => setAbout(false)}>gallery</a> : <a onClick={() => setAbout(true)}>about me</a> }
        </footer>
      </div>
    </div>
  );
}

export default App;
