import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import AspectRatio from './AspectRatio/AspectRatio'
import Data from "../data/art.json";

const FullScreen = styled('div')(({ theme }) => ({
 
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'

  }));
  
  const FullScreenImage = styled('img')(({ theme }) => ({
    margin: 'auto',
    maxWidth: '-webkit-fill-available',
    maxHeight: '-webkit-fill-available'
   
  }));


const Root = styled('div')(({ theme }) => ({
 
    maxHeight: '-webkit-fill-available',
    overflowY: 'scroll',
    paddingLeft: '20px',
    paddingRight: '20px'

  }));
const RootStyle = styled('div')(({ theme }) => ({
    maxHeight: 'calc(100vh - 400px)',
    maxWidth: 'calc((100vh - 400px) * 4032 / 3024)',
    overflow: 'hidden',
    boxSizing: 'border-box',
    border: '0px solid white',
    borderRadius: '10px',
    margin: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '-webkit-box-shadow':'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)',
    '-moz-box-shadow':'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)',
    boxShadow:'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)',
    cursor: 'pointer'

  }));
const ResponsiveIframe = styled('img')(({ theme }) => ({
    maxHeight: 'calc(100vh - 400px)',
    maxWidth: '100%'
   
  }));
const Title = styled('div')(({ theme }) => ({
    marginBottom: '40px',
    fontSize: '30px',
    fontFamily: 'cursive',
    color: 'rgb(39, 23, 38)',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 'bold',
    lineHeight: '50px',
    letterSpacing: '6px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative'
   
  }));
  const Left = styled('div')(({ theme }) => ({
    display: 'inline-block',
    cursor:'pointer',
    position: 'absolute',
    left: ['0px'],
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
        left: '50px'
    },
    ['@media (min-width:1200px)']: { // eslint-disable-line no-useless-computed-key
        left: '20%'
    }
   
  }));
  const Right = styled('div')(({ theme }) => ({
    display: 'inline-block',
    cursor:'pointer',
    position: 'absolute',
    right: ['0px'],
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
        right: '50px'
    },
    ['@media (min-width:1200px)']: { // eslint-disable-line no-useless-computed-key
        right: '20%'
    }
   
  }));
  const Center = styled('div')(({ theme }) => ({
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
   
  }));
const ImageContainer = () => {
    const [id, setId] = useState(0)
    const [full, setFull] = useState(false)
    const next = () => {
        if(id > 16)
            setId(0);
        else setId((id) => id + 1)
    }
    const prev = () => {
        if(id == 0)
            setId(17);
        else setId((id) => id - 1)
    }
    const fullscreen = () => {

    }
    return (    
        <>
        <Root>
            <RootStyle onClick={() => setFull(true)}>
            
                    <AspectRatio ratio={4032 / 3024}>
                        <ResponsiveIframe src={`/art/${Data.art[id].src}`} />
                    </AspectRatio>

            </RootStyle>
            <Title>
                <Left onClick={prev}>{'<'}</Left>
                <Center>{Data.art[id].title}</Center>
                <Right onClick={next}>{'>'}</Right>
            </Title>
        </Root>
        {full && 
            <FullScreen onClick={() => setFull(false)}>
                <FullScreenImage src={`/art/${Data.art[id].src}`} />
            </FullScreen>
        }
        </>
    )
}

export default ImageContainer

