import * as React from "react"
import { styled } from '@mui/material/styles';

interface Props {
  children?: any
  /**
   * The width divided by the height. This ratio can be passed in
   * using JavaScript division syntax. So, to get a 16:9 ratio, 
   * simply pass `ratio={16/9}`.
   */
  ratio: number
}

const InnerWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const AspectRatio = ({ children, ratio }: Props) => { 
    const OuterWrapper = styled('div')(({ theme }) => ({
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: `${(1 / ratio) * 100}%`
    }));

    return (
    
        <OuterWrapper>
            <InnerWrapper>
            {children}
            </InnerWrapper>
        </OuterWrapper>
    )
}
export default AspectRatio
