import React from 'react'
import { styled } from '@mui/material/styles';
import AspectRatio from './AspectRatio/AspectRatio'

const Root = styled('div')(({ theme }) => ({
 
    maxHeight: '-webkit-fill-available',
    overflowY: 'scroll',
    paddingLeft: '20px',
    paddingRight: '20px',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
        paddingLeft: '0',
        paddingRight: '0',
    },

  }));
const RootStyle = styled('div')(({ theme }) => ({
    maxWidth: '-webkit-fill-available',
    maxHeight: '-webkit-fill-available',
    overflow: 'hidden',
    boxSizing: 'border-box',
    border: '0px solid white',
    borderRadius: '10px',
    marginTop: '50px',
    marginBottom: '50px',
    marginLeft: '0',
    marginRight: '0',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key

        width: '100%',
        margin: '50px',
    },
    '-webkit-box-shadow':'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)',
    '-moz-box-shadow':'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)',
    boxShadow:'0px -1px 25px rgba(0,0,0,0.5), 0px 1px 25px rgba(0,0,0,0.7)'

  }));
  const H1 = styled('h1')(({ theme }) => ({
    fontSize: '50px',
    fontFamily: 'cursive',
    color: 'rgb(39, 23, 38)',
    margin: '40px',
    fontWeight: 'bold',
    lineHeight: '50px',
    letterSpacing: '6px'
   
  }));
const Para = styled('p')(({ theme }) => ({
    fontSize: '30px',
    fontFamily: 'serif',
    color: 'rgb(39, 23, 38)',
    margin: '40px',
    fontWeight: 'bold',
    lineHeight: '50px',
    letterSpacing: '4px'
   
  }));
  const A = styled('a')(({ theme }) => ({
    textDecoration: 'none !important',
    color: 'rgb(39, 23, 38)',
    '&:hover': {
        color: '#794676 !important'
    }
  }));
  const Scroll = styled('div')(({ theme }) => ({
    overflow: 'auto',
    maxHeight: 'calc(100vh - 300px);',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key

        maxHeight: 'calc(100vh - 300px);'
    },
  }));

const ImageContainer = () => {
    return (    
        <Root>
        <RootStyle>
           
              
            <Scroll>
            <H1>About Me</H1>
            <Para>
                Hi, my name is Brooklynn. I have been working on pour art for seven years and now have begun to release my work. Each image is created by taking a photo of wet pour painting and
                digitally enhansing the image to bring out vibrant colors that tickle your brain. The images chosen for release were selected from hundreds of photos of different pours I have done.
                The majority of my work is done staritng with the three primary colors, black and white. The unique colors and shapes are then drawn out by gravity creating a piece inspired by the
                the laws of the universe.
            </Para>
            <Para>
                <A href='https://twitter.com/brooklynn_art' target='_blank'>@brooklynn_art</A>
            </Para>
            </Scroll>
            
        </RootStyle>
        </Root>
    )
}

export default ImageContainer

